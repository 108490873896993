<template>
  <div class="cr-task-check-list-wrapper">
    <h3 class="black--text pt-4 pb-2 pl-1">체크리스트</h3>
    <Checklist
      :task="newTask"
      @add:checklist="addChecklist"
      @update:checklist="updateChecklist"
      @delete:checklist="deleteChecklist"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Checklist from "@/flow/views/components/commons/task/checklist";

export default {
  components: { Checklist },
  computed: {
    ...mapState("flowTasks", ["newTask"])
  },
  methods: {
    ...mapMutations("flowTasks", ["SET_NEW_TASK"]),
    addChecklist(item) {
      const checklist = [...(this.newTask?.checklist || [])];

      this.SET_NEW_TASK({ checklist: [...checklist, item] });
    },
    updateChecklist(item) {
      const { key } = item;
      const checklist = [...(this.newTask?.checklist || [])];
      const index = checklist.findIndex(c => c.key === key);
      checklist[index] = item;

      this.SET_NEW_TASK({ checklist: [...checklist] });
    },
    deleteChecklist(item) {
      const { key } = item;
      const checklist = this.newTask.checklist.filter(c => c.key !== key);

      this.SET_NEW_TASK({ checklist: [...checklist] });
    }
  }
};
</script>
