<template>
  <div class="cr-left-panel-wrapper">
    <Content />
    <Checklist class="mt-2" />
    <SubTasks class="my-2" />
  </div>
</template>

<style lang="scss" scoped>
.cr-left-panel-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 20px;
}
</style>

<script>
import Content from "./content";
import Checklist from "./checklist";
import SubTasks from "./subTasks";

export default {
  components: { Content, Checklist, SubTasks }
};
</script>
