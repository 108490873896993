<template>
  <div class="cr-right-panel-wrapper">
    <h3 class="black--text pt-4 pb-2 pl-1">세부 정보</h3>

    <div class="cr-right-panel">
      <StatusAndPriority />
      <InfoContainer />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-right-panel-wrapper::v-deep {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 350px;
  .cr-right-panel {
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.03);
    border: thin solid rgba(0, 0, 0, 0.04);
    .cr-title {
      font-weight: bold;
      font-size: 14px;
      padding: 16px 20px 12px;
    }
  }
}
</style>

<script>
import StatusAndPriority from "./statusAndPriority";
import InfoContainer from "./infoContainer";

export default {
  components: { StatusAndPriority, InfoContainer }
};
</script>
