<template>
  <div class="cr-priority-wrapper px-2">
    <div class="cr-title pl-2">
      우선순위
    </div>

    <Priority
      noArrow
      fitContent
      :task="newTask"
      @change:priority="changePriority"
    />
  </div>
</template>

<style lang="scss" scoped>
.cr-priority-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100px;
  width: 100%;
  max-width: 50%;
}
</style>

<script>
import { mapState, mapMutations } from "vuex";
import Priority from "@/flow/views/components/commons/task/priority";

export default {
  components: { Priority },
  computed: {
    ...mapState("flowTasks", ["newTask"])
  },
  methods: {
    ...mapMutations("flowTasks", ["SET_NEW_TASK"]),
    changePriority(priority) {
      this.SET_NEW_TASK({ priority });
    }
  }
};
</script>
