<template>
  <div class="cr-task-content-wrapper">
    <h3 class="black--text pt-4 pb-2 pl-1">상세 내용</h3>
    <Content ref="content" mode="add" :content.sync="_content" />
  </div>
</template>

<style lang="scss" scoped>
.cr-task-content-wrapper::v-deep {
  .v-input.v-textarea {
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.03);
    border: thin solid rgba(0, 0, 0, 0.04);
    fieldset {
      border: none !important;
    }
  }
}
</style>

<script>
import { mapState, mapMutations } from "vuex";
import Content from "@/flow/views/components/commons/task/content";

export default {
  components: { Content },
  computed: {
    ...mapState("flowTasks", ["newTask"]),
    _content: {
      get() {
        return this.newTask?.content || "";
      },
      set(content) {
        this.SET_NEW_TASK({ content });
      }
    }
  },
  methods: {
    ...mapMutations("flowTasks", ["SET_NEW_TASK"])
  }
};
</script>
