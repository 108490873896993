<template>
  <div class="cr-info-container-wrapper pb-6">
    <Item v-for="item in items" :key="item.value" :item="item" />
  </div>
</template>

<style lang="scss" scoped>
.cr-info-container-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>

<script>
import Item from "./Item.vue";

export default {
  components: { Item },
  data() {
    return {
      items: [
        { title: "그룹", value: "Group" },
        { title: "담당자", value: "Assignee" },
        { title: "시작일", value: "StartDate" },
        { title: "마감일", value: "DueDate" },
        { title: "진척률", value: "Progress" }
      ]
    };
  }
};
</script>
