<template>
  <div class="cr-add-task-wrapper">
    <div class="cr-scroll">
      <Title ref="title" v-on="$listeners" />

      <div class="d-flex justify-space-between">
        <LeftPanel />
        <RightPanel />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-add-task-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  .cr-scroll {
    overflow: hidden auto;
    position: absolute;
    top: 8px;
    left: 12px;
    right: 12px;
    bottom: 0px;
  }
}
</style>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Title from "./title";
import LeftPanel from "./leftPanel";
import RightPanel from "./rightPanel";

export default {
  components: { Title, LeftPanel, RightPanel },
  computed: {
    ...mapState("flowDialog", ["isConfirmed"])
  },
  watch: {
    isConfirmed(isConfirmed) {
      if (!isConfirmed) return;

      this.confirm();
    }
  },
  methods: {
    ...mapMutations("flowDialog", ["SET_CONFIRMED", "CLOSE_DIALOG"]),
    ...mapActions("flowTasks", ["addTask"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    async confirm() {
      if (!this.$refs.title.validTitle()) {
        return this.SET_CONFIRMED(false);
      }

      this.addTask();
    }
  }
};
</script>
