<template>
  <div class="cr-task-sub-wrapper">
    <h3 class="black--text pt-4 pb-2 pl-1">하위 업무</h3>
    <SubTask
      :task="newTask"
      @add:subTask="addSubTask"
      @change:Title="changeTitle"
      @change:rangeDate="changeRangeDate"
      @click:member="clickMember"
      @delete:subTask="deleteSubTask"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import SubTask from "@/flow/views/components/commons/task/subTask";

export default {
  components: { SubTask },
  computed: {
    ...mapState("flowTasks", ["newTask"]),
    subTasks() {
      const { subTasks } = this.newTask;
      return [...(subTasks || [])].map(s => ({ ...s }));
    }
  },
  methods: {
    ...mapMutations("flowTasks", ["SET_NEW_TASK"]),
    addSubTask(task) {
      this.SET_NEW_TASK({ subTasks: [...this.subTasks, task] });
    },
    changeTitle(task) {
      const { key, title } = task;
      const subTasks = [...this.subTasks];
      const index = subTasks.findIndex(c => c.key === key);
      subTasks[index].title = title;

      this.SET_NEW_TASK({ subTasks: [...subTasks] });
    },
    changeRangeDate({ task, rangeDate }) {
      const { key } = task;
      const [startDateTimeMillis, dueDateTimeMillis] = rangeDate;
      const subTasks = [...this.subTasks];
      const index = subTasks.findIndex(c => c.key === key);
      subTasks[index].startDateTimeMillis = startDateTimeMillis;
      subTasks[index].dueDateTimeMillis = dueDateTimeMillis;

      this.SET_NEW_TASK({ subTasks: [...subTasks] });
    },
    clickMember({ task, member }) {
      const { key } = task;
      const { userId } = member;
      const subTasks = [...this.subTasks];
      const index = subTasks.findIndex(c => c.key === key);
      const { assignees: _assignees } = subTasks[index];
      let assignees = [..._assignees, member];
      if (_assignees.find(a => a.userId === userId)) {
        assignees = assignees.filter(m => m.userId !== userId);
      }

      subTasks[index].assignees = assignees;
      this.SET_NEW_TASK({ subTasks: [...subTasks] });
    },
    deleteSubTask(task) {
      const { key } = task;
      const subTasks = this.newTask.subTasks.filter(c => c.key !== key);
      this.task = { subTasks: [...subTasks] };
    }
  }
};
</script>
