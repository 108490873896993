<template>
  <div class="cr-status-wrapper px-2">
    <div class="cr-title pl-2">
      상태
    </div>

    <Status noArrow fitContent :task="newTask" @change:status="changeStatus" />
  </div>
</template>

<style lang="scss" scoped>
.cr-status-wrapper {
  display: flex;
  flex-direction: column;
  height: 100px;
  width: 100%;
  max-width: 50%;
}
</style>

<script>
import { mapState, mapMutations } from "vuex";
import Status from "@/flow/views/components/commons/task/status";

export default {
  components: { Status },
  computed: {
    ...mapState("flowTasks", ["newTask"])
  },
  methods: {
    ...mapMutations("flowTasks", ["SET_NEW_TASK"]),
    changeStatus(status) {
      this.SET_NEW_TASK({ status });
    }
  }
};
</script>
