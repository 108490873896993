<template>
  <div class="pt-3 pb-2">
    <Title
      ref="title"
      mode="edit"
      v-model="_title"
      @enter="SET_CONFIRMED(true)"
    />
  </div>
</template>

<style lang="scss" scoped>
::v-deep .v-textarea {
  border-radius: 8px !important;
  background-color: rgba(0, 0, 0, 0.03);
  fieldset {
    border-color: rgba(0, 0, 0, 0.04) !important;
  }
}
</style>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { isBlank } from "@/commons/utils/validation";
import Title from "@/flow/views/components/commons/task/title";

export default {
  components: { Title },
  computed: {
    ...mapState("flowTasks", ["newTask"]),
    _title: {
      get() {
        return this.newTask.title || "";
      },
      set(title) {
        this.SET_NEW_TASK({ title });
      }
    }
  },
  methods: {
    ...mapMutations("flowDialog", ["SET_CONFIRMED"]),
    ...mapMutations("flowTasks", ["SET_NEW_TASK"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    validTitle() {
      if (!isBlank(this._title)) return true;

      this.$refs.title.focus();
      this.openSnackbar({ message: "업무 제목은 필수 값입니다." });
      return false;
    }
  }
};
</script>
